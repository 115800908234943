export const searchPosts = /* GraphQL */ `
  query SearchPosts(
    $filter: SearchablePostFilterInput
    $sort: SearchablePostSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        author
        authorRef {
          name
          displayName
          email
          university
          universityName
          studyArea
          studyAreaName
          course
          courseName
          studyStatus
          about
          interestedChannels
          photoURL
          isPartner
          createdAt
          updatedAt
        }
        content
        medias
        comments {
          items {
            id
            author
          }
          nextToken
        }
        likes {
          items {
            id
            user
            postID
          }
          nextToken
        }
        ogMetaData {
          items {
            ogTitle
            ogDescription
            ogVideoUrl
            ogVideoHeight
            ogVideoWidth
            ogVideoType
            ogImageUrl
            ogImageHeight
            ogImageWidth
            ogImageType 
            ogDate
            ogSiteName
            ogUrl
            requestUrl
          }
        }
        isDeleted
        channelID
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;