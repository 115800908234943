<template>
  <keep-alive>
    <div id="search-page">
      <div class="search-meta flex flex-wrap justify-between mt-6">
        <span>Search Results for "{{ this.$route.query.query }}"</span>
      </div>
      <div>
        <span class="mb-4">{{ searchUsers.length + searchPosts.length }} Results</span>
      </div>

      <div
        v-if="searchUsers.length + searchPosts.length === 0"
        class="text-lg mt-8"
      >
        <h5>Sorry we can't find any results</h5>
      </div>
      <!-- SEARCH RESULTS -->
      <div v-else class="vx-row mt-4 md:flex-row flex-col-reverse">
        <div class="vx-col md:w-2/3 sm:w-full">
          <div>
            <div v-if="searchUsers.length !== 0">
              <h4>Users</h4>
              <div class="flex justify-start" v-if="searchUsers.length !== 0">
                <vs-card
                  class="mr-4 mt-2 w-1/3 cursor-pointer"
                  v-for="(user, index) in searchUsers"
                  :key="index"
                  
                >
                  <div class="flex justify-center" @click="redirectToProfile(user)">
                    <vs-avatar
                      size="large"
                      class="mx-auto"
                      :src="user.photoURL"
                    />
                  </div>
                  <div class="flex flex-col space-between">
                    <div class="text-center">
                      <a class="text-lg" target="_blank" rel="nofollow">{{
                        user.displayName
                      }}</a
                      ><br />
                    </div>
                    <p class="block text-md text-center" v-if="!user.isPartner">
                      {{ user.studyStatus }}
                    </p>
                    <p class="block text-md text-center" v-if="user.isPartner && user.partnerData">
                      {{ user.partnerData.partnerType | partnerTypeFormatter }}
                    </p>
                    
                    <p
                      class="block mb-1 italic text-sm text-center"
                      target="_blank"
                      rel="nofollow"
                      v-if="user.about !== ''"
                    >
                      {{ user.about }}
                    </p>
                    <!--
                    <div class="text-center">
                        <vs-button
                          v-if="user.isFollowed && !logined"
                          class="align-bottom"
                          type="gradient"
                          color="#DF123D"
                          size="small"
                          gradient-color-secondary="#DF123D"
                          
                          @click="unfollowUser(user, index)"
                          >Unfollow</vs-button
                        >
                        <vs-button
                           v-if="!user.isFollowed && !logined"
                          class="align-bottom"
                          type="gradient"
                          color="#DF123D"
                          size="small"
                          gradient-color-secondary="#DF123D"
                          @click="followUserInSearch(user, index)"
                          
                          >Follow</vs-button
                        >
                    </div>
                    -->
                  </div>
                </vs-card>
              </div>
            </div>
            <div class="mt-4" v-if="searchPosts.length !== 0">
              <h4>Posts</h4>
              <Post
                @onClick="viewCurrentPost(post)"
                class="post-search-result w-full cursor-pointer"
                :actor="''"
                :action="''"
                v-for="(post, index) in searchPosts"
                :feed="post"
                :key="index"
                :displayComment="false"
                :displayPostOption="false"
                :displayShare="false"
              />
            </div>
          </div>
        </div>
        <!-- <vs-pagination :total="10" v-model="currentPage" class="mt-base self-center"></vs-pagination> -->
      </div>
      <div id="salesiqScript"></div>

    </div>
  </keep-alive>
</template>

<script>
import Post from '../../layouts/components/coursepal/Post/Post'
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { searchUsers, partnerByUser } from '@/graphql/queries'
import { searchPosts } from '@/graphql/searchPosts'

export default {
  name: 'Search',
  data () {
    return {
      currentPage: 1,
      searchUsers: [],
      searchPosts: [],
      logined:false
    }
  },
  components: {
    Post
  },
  watch: {
    $route: 'fetchData'
  },
  created () {
    this.fetchData()
  },
  methods: {
    unfollowUser (user, index) {
      user.isFollowed = false
      this.searchUsers.splice(index, 1, user)
      this.$store.dispatch('follow/unfollowCurrentAuthor', user.name)
    },
    followUserInSearch (user, index) {
    
      user.isFollowed = true
      this.searchUsers.splice(index, 1, user)
      this.$store.dispatch('follow/followUserInSearch', user)

       if (user.enablePostNotification !== false) {

                this.$store.dispatch('newsfeed/sendMail', {
                    user_name: this.$store.state.auth.userData.name,
                    activity_user: user.displayName,
                    activity: 'FOLLOW',
                    template_code: 'send_notification',
                    activity_user_mail: user.email,
                    post_content: null,
                    activity_user_image_url: this.$store.state.auth.userData.photoURL,
                    post_image_url: null,
                    profile_url: `https://mypals.app/profile/${this.$store.state.auth.userData.name}`,
                    post_url: null,

                })
            }
    },
    isFollowed (currentUser) {
      if (this.$store.state.follow.followings.items.length === 0) return false
      for (const follow of this.$store.state.follow.followings.items) {
        if (currentUser === follow.user) {
          return true
        }
      }
      return false
    },
    viewCurrentPost (post) {
      this.$router.push(`/post?postId=${post.id}`)
    },
    redirectToProfile (user) {
      if (user.isPartner) {
        this.$router.push(`/partner/${user.name}`)
      } else {
        this.$router.push(`/profile/${user.name}`)
      }
    },
    async fetchData () {
      try {
        this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading
        })
        await this.$store.dispatch('follow/fetchFollowings')
        const searchRes = await API.graphql(
          graphqlOperation(searchUsers, {
            filter: {
              displayName: {
                matchPhrase: this.$route.query.query
              }
            }
          })
        )

        this.searchUsers = searchRes.data.searchUsers.items
        console.log(this.searchUsers)
        console.log(this.$store.state.auth.userData.name)

        for(var user=0;user<this.searchUsers.length;user++){
               
        if(this.searchUsers[user].displayName==this.$store.state.auth.userData.name){
          this.logined=true
          console.log(this.logined)
        }
        }
       
        if (this.searchUsers.length !== 0) {
          for (const user of this.searchUsers) {
            if (user && user.isPartner) {
              // fetch partner data
              const partnerRes = await API.graphql(graphqlOperation(partnerByUser, {
                user: user.name
              }))

              if (partnerRes.data.partnerByUser.items.length !== 0) {
                user.partnerData = partnerRes.data.partnerByUser.items[0]
              }
            }
            user.isFollowed = this.isFollowed(user.name)
          }
        }

        const searchPostsRes = await API.graphql(
          graphqlOperation(searchPosts, {
            filter: {
              content: {
                matchPhrasePrefix: this.$route.query.query
              },
              isDeleted: {
                eq: false
              }
            }
          })
        )

        for (const feed of searchPostsRes.data.searchPosts.items) {
          feed.mediaFiles = []
          if (feed.medias && feed.medias.length > 0) {
            let mediaFiles = feed.medias.map((m) => Storage.get(m))
            mediaFiles = await Promise.all(mediaFiles)
            feed.mediaFiles = mediaFiles
          }

          feed.isLiked = false
          for (const like of feed.likes.items) {
            if (like.user === this.$store.state.auth.userData.name) {
              feed.isLiked = true
              break
            }
          }

          if (feed.authorRef && feed.authorRef.isPartner) {
            const partnerByUserRes = await API.graphql(graphqlOperation(partnerByUser, {
              user: feed.author
            }))
            feed.partnerData = partnerByUserRes.data.partnerByUser.items[0]
          }

        }

        this.searchPosts = searchPostsRes.data.searchPosts.items
        this.$store.dispatch(
          'search/updateSearchedToken',
          this.$route.query.query
        )
        this.$vs.loading.close()
      } catch (err) {
        console.log(err)
      }
    }
  } 
}

</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/search.scss";
</style>
